<template>
  <div>
    <QueryForm ref="form" v-model="form" :query-btn-loading="tableLoading" @query="handleQuery" @reset="resetClick">

      <el-form-item label="供应商">
        <el-select v-model="form.vendorId" style="width:100%;" value-key="id" placeholder="请选择" filterable clearable>
          <el-option
            v-for="item in vendorOptions"
            :key="item.id"
            :label="item.vendorName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="采购主体">
        <el-select v-model="form.cargoOwnerId" style="width:100%;" value-key="id" placeholder="请选择" filterable clearable>
          <el-option
            v-for="item in baseWareHouseData"
            :key="item.id"
            :label="item.cargoOwnerName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="合同编号">
        <el-input v-model="form.purchaseFrameworkContractCode" />
      </el-form-item>

      <el-form-item label="用印签署状态">
        <Select
          v-model="form.signState"
          style="width:100%;"
          :select-options="_getAllCommodityDict('SIGN_STATE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="用印类别">
        <Select
          v-model="form.signPrintType"
          :select-options="_getAllCommodityDict('SIGN_PRINT_TYPE')"
          :configuration="optionsConfig"
          clearable
        />
      </el-form-item>

      <el-form-item label="印章所属公司">
        <Select
          v-model="form.organizationCode"
          style="width:100%;"
          api-key="organization"
          clearable
        />
      </el-form-item>

      <el-form-item label="印章类型">
        <Select
          v-model="form.signSealType"
          api-key="sealtype"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="签署类型">
        <Select
          v-model="form.signSignatureType"
          :select-options="_getAllCommodityDict('SIGN_SIGNATURE_TYPE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="用印发起人">
        <Select
          v-model="form.signSponsor"
          api-key="newUserList"
          clearable
        />
      </el-form-item>

      <el-form-item label="签定日期">
        <el-date-picker
          v-model="form.value1"
          type="daterange"
          range-separator="~"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
    </QueryForm>
    <el-row :span="24" class="mb-3">
      <el-col :span="24">
        <el-button type="primary" @click="dialogVisible=true">上传</el-button>
        <el-button type="primary" :disabled="!multipleSelectionTable.length" @click="handleApply">用印申请</el-button>
        <el-button type="primary" @click="handleDownload">下载</el-button>
      </el-col>
    </el-row>
    <Table
      :table-data="tableData"
      :max-height="'600px'"
      :columns="frameContractcolumns"
      :page-obj="pager"
      :check="true"
      :loading="tableLoading"
      @handleSelectionChange="val => {multipleSelectionTable=val}"
    >
      <el-table-column slot="number" label="订单合同数量" align="center" min-width="120">
        <template slot-scope="scope">
          <el-button type="text" :loading="scope.row.numberLoading" @click="showDeatail(scope.row)">{{ scope.row.orderContractNum }}</el-button>
        </template>
      </el-table-column>
      <el-table-column slot="name" label="操作人" align="center" min-width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.modifyByName ||scope.row.createByName }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="time" label="操作时间" align="center" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.modifyTime ||scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" align="center" fixed="right" width="150">
        <template slot-scope="scope">
          <!-- 生效 确认框 -->
          <el-button v-if="showDelete('valid',scope.row)" type="text" @click="cancel(scope.row)">作废</el-button>
          <el-button v-if="showDelete('delete',scope.row)" type="text" @click="deleteContract(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>
    <el-dialog
      title="合同效期调整"
      :visible.sync="cancelDialogVisible"
      width="500px"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="cancelForm" label-position="right" label-width="100px" :model="cancelForm" :rules="cancelRules">
        <el-form-item label="失效日期：" prop="expiryDate">
          <el-date-picker
            v-model="cancelForm.expiryDate"
            type="date"
            style="width:100%;"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="accountPeriodOptions"
          />
        </el-form-item>
        <el-form-item label="作废原因：">
          <el-input
            v-model="cancelForm.cancelReason"
            placeholder=""
            type="textarea"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="submitLoading" :disabled="Boolean(row.expiryDate===cancelForm.expiryDate)" @click="submit">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="合同附件上传"
      :visible.sync="dialogVisible"
      width="500px"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="dialogForm" label-position="right" label-width="100px" :model="dialogForm" :rules="dialogRules">
        <el-form-item label="合同编号：" prop="purchaseFrameworkContractCode">
          <el-input v-model="dialogForm.purchaseFrameworkContractCode" />
        </el-form-item>
        <el-form-item label="采购主体：" prop="cargoOwnerId">
          <el-select v-model="dialogForm.cargoOwnerId" style="width:100%;" value-key="id" placeholder="请选择" filterable clearable>
            <el-option
              v-for="item in baseWareHouseData"
              :key="item.id"
              :label="item.cargoOwnerName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="供应商：" prop="vendorId">
          <el-select v-model="dialogForm.vendorId" style="width:100%;" value-key="id" placeholder="请选择" filterable>
            <el-option
              v-for="item in vendorOptions"
              :key="item.id"
              :label="item.vendorName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="签订日期：" prop="signingDate">
          <el-date-picker
            v-model="dialogForm.signingDate"
            type="date"
            style="width:100%;"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="生效日期：" prop="effectiveDate">
          <el-date-picker
            v-model="dialogForm.effectiveDate"
            type="date"
            style="width:100%;"
            value-format="yyyy-MM-dd"
            :picker-options="accountPeriodDateStartOptions"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="失效日期：" prop="expiryDate">
          <el-date-picker
            v-model="dialogForm.expiryDate"
            type="date"
            style="width:100%;"
            value-format="yyyy-MM-dd"
            :picker-options="accountPeriodDateEndOptions"
            placeholder="选择日期"
          />
        </el-form-item>
        <!-- :prop="file?'':'file'" -->
        <el-form-item ref="file" label="合同附件：" prop="file">
          <el-upload
            ref="uploadForm"
            action=""
            :http-request="uploadFile"
            :limit="1"
            :on-remove="remove"
            :file-list="fileList"
            :auto-upload="false"
            :on-change="fileDateChange"
          >
            <el-button type="primary">导入文件</el-button>
          </el-upload>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="checkLoading" @click="save">保存</el-button>
      </span>
    </el-dialog>
    <PoDetail v-model="dialogContractVisible" :title="'订单合同详情'" :detail-table="contractDetails" :columns="frameContractDetailscolumns" />
    <DownloadFile v-model="dowmloadDialogVisible" :row="multipleSelectionTable[0]" :file-type-list="fileTypeList" :api="downloadFrameFile" />
    <ApplicationSure
      v-model="applyDialogVisible"
      :multiple-selection="multipleSelectionTable"
      :api="frameApproval"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import { frameApproval, downloadFrameFile, getPOContractList, cancelContract, uploadContract, queryVendorList, getContractList, deleteContract } from '@/api/scm-api'
import { frameContractcolumns, frameContractDetailscolumns } from '@/constant/tablecolumns'
import Table from '@/components/Table'
import Paging from '@/components/Pagination'
import { getCargoOwner } from '@/api/listSelection.js'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import QueryForm from '@/components/QueryForm'
import ApplicationSure from '../components/ApplicationSure'
import DownloadFile from '../components/DownloadFile'
import PoDetail from '../components/PoDetail'
export default {
  components: { Table, Paging, Select, QueryForm, ApplicationSure, DownloadFile, PoDetail },
  mixins: [commodityInfoDict],
  data() {
    const validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('page.required')))
      } else {
        const reg = /^[a-zA-Z0-9]{1,32}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('限制32位内字母数字组合'))
        }
      }
    }
    return {
      accountPeriodDateStartOptions: {
        disabledDate: (time) => {
          if (this.dialogForm.expiryDate) {
            return time.getTime() > new Date(this.dialogForm.expiryDate).getTime()
          }
        }
      },
      accountPeriodDateEndOptions: {
        disabledDate: (time) => {
          if (this.dialogForm.effectiveDate) {
            return time.getTime() < new Date(this.dialogForm.effectiveDate).getTime() - 24 * 60 * 60 * 1000
          }
        }
      },
      accountPeriodOptions: {
        disabledDate: (time) => {
          if (this.row.expiryDate) {
            return time.getTime() > new Date(this.row.expiryDate).getTime() || time.getTime() < new Date(this.row.effectiveDate) - 24 * 60 * 60 * 1000
          }
        }
      },
      frameApproval,
      submitLoading: false,
      cancelRules: {
        expiryDate: { required: true, message: '必填', trigger: 'change' }
      }, row: {},
      cancelForm: { cancelReason: '' },
      baseWareHouseData: [],
      file: '',
      form: { value1: [] }, showRow: false, frameContractcolumns, cancelDialogVisible: false,
      dialogVisible: false,
      dialogRules: { purchaseFrameworkContractCode: [{ required: true, message: '必填', trigger: 'blur' }, { validator: validateCode, trigger: 'blur' }],
        cargoOwnerId: { required: true, message: '必填', trigger: 'change' },
        vendorId: { required: true, message: '必填', trigger: 'change' },
        signingDate: { required: true, message: '必填', trigger: 'change' },
        effectiveDate: { required: true, message: '必填', trigger: 'change' },
        expiryDate: { required: true, message: '必填', trigger: 'change' },
        file: { required: true, message: '必填', trigger: 'change' }
      },
      fileList: [],
      dialogForm: {
        file: '',
        purchaseFrameworkContractCode: '',
        cargoOwnerId: '',
        vendorId: '',
        signingDate: '',
        effectiveDate: '',
        expiryDate: ''
      }, checkLoading: false,
      tableLoading: false, vendorOptions: [], tableData: [], pager: { size: 20, current: 1, total: 0 },
      contractDetails: [],
      frameContractDetailscolumns,
      dialogContractVisible: false,
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      multipleSelectionTable: [],
      applyDialogVisible: false,
      dowmloadDialogVisible: false,
      downloadFrameFile,
      fileTypeList: [{ label: '供应商单方盖章文件', value: 'unilateralUrl' }, { label: '双方盖章文件', value: 'multiUrl' }]

    }
  },
  computed: {
    queryParams() {
      const { value1 = [] } = this.form
      const [signingStartDate, signingEndDate] = value1 || []
      return Object.assign({}, this.form, this.pager, { signingStartDate, signingEndDate })
    },
    saveParams() {
      const formDataParams = new FormData()
      const { vendorId, cargoOwnerId } = this.dialogForm
      const { vendorName = '' } = this.vendorOptions.find(item => item.id === vendorId)
      const { cargoOwnerName = '', cargoOwnerCode = '' } = this.baseWareHouseData.find(item => item.id === cargoOwnerId)
      const params = Object.assign({}, { vendorName, cargoOwnerName, cargoOwnerCode }, this.dialogForm,)
      console.log(params)
      Object.keys(params).forEach(key => formDataParams.set([key], params[key]))
      return formDataParams
    },
    lengthFlag() {
      return this.multipleSelectionTable.length < 11
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        this.$refs.dialogForm.resetFields()
        this.$refs.dialogForm.clearValidate()
        this.dialogForm.file = ''
        this.$refs.file.value = ''
      this.$refs.uploadForm?.clearFiles()
      } else {
        this.$refs.dialogForm && this.$refs.dialogForm.clearValidate()
      }
    },
    'cancelDialogVisible'(val) {
      if (!val) {
        this.$refs.cancelForm.clearValidate()
      }
    }
  },
  created() {

  },
  mounted() {
    this._queryVendorList()
    this.getAllWarehouseData()
    this.handleQuery()
  },
  methods: {
    showDelete(flag, { signState, expiryDate, orderContractNum }) {
      return flag === 'valid' ? new Date(expiryDate).getTime() > new Date().getTime() - 24 * 60 * 60 * 1000 && [0, 2, 5, 8, 9].includes(signState) || [9].includes(signState)
        : orderContractNum === 0 && [2, 5, 8].includes(signState)
    },
    async handleDownload() {
      if (this.multipleSelectionTable.length === 1) {
        this.dowmloadDialogVisible = true
      } else {
        this.$message.warning('请勾选一条数据下载')
      }
    },
    handleApply() {
      const cargoFlag = this.multipleSelectionTable.every(item => item.cargoOwnerId === this.multipleSelectionTable[0].cargoOwnerId)
      const statusFlag = this.multipleSelectionTable.every(item => [2, 5, 8].includes(item.signState))
      const confirmFlag = this.multipleSelectionTable.some(item => [5, 8].includes(item.signState))

      if (!cargoFlag) {
        this.$message.warning('采购主体不一致，无法发起用印申请！')
        return
      }
      if (!statusFlag) {
        this.$message.warning('仅限印签署状态为“供应商已盖章”、“审批拒绝”、“签署失败”时可操作')
        return
      }

      if (confirmFlag) {
        this.lengthFlag ? this.$confirm(`${this.multipleSelectionTable.length === 1 ? '当前' : '存在'}合同此前用印申请被拒绝，请确认是否重新发起审批流程?`, '提示', {
          confirmButtonText: '确定',
          showCancelButton: true,
          type: 'warning'
        }).then(() => {
          this.applyDialogVisible = true
        }) : this.$message.warning('至多选择10条数据发起用印申请！')
      } else {
        this.lengthFlag ? this.applyDialogVisible = true : this.$message.warning('至多选择10条数据发起用印申请！')
      }
    },
    remove() {
      this.dialogForm.file = ''
      this.fileList = []
    },
    fileDateChange(file, fileList) {
      this.fileList = fileList
      this.dialogForm.file = file.raw
      this.$refs.file.clearValidate()
    },
    async uploadFile() {
      try {
        this.checkLoading = true
        const { code, msg } = await uploadContract(this.saveParams)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.dialogVisible = false
          this.handleQuery()
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.checkLoading = false
        this.dialogForm.file = ''
        this.fileList = []
      }
    },
    async   showDeatail(row) {
      try {
        this.$set(row, 'numberLoading', true)
        const { code, datas } = await getPOContractList({ frameworkContractCode: row.purchaseFrameworkContractCode })
        if (code === 0) {
          this.contractDetails = datas
          this.dialogContractVisible = true
        }
      } finally {
        this.$set(row, 'numberLoading', false)
      }
    },
    submit() {
      this.$refs.cancelForm.validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            const { id, expiryDate, cancelReason = '' } = this.cancelForm
            const { code, msg } = await cancelContract({ id, expiryDate, cancelReason })
            if (code === 0) {
              this.$notify({
                title: msg,
                message: msg,
                type: 'success'
              })
              this.cancelDialogVisible = false
              this.handleQuery()
            }
          } finally {
            this.submitLoading = false
          }
        }
      })
    },
    save() {
      this.$refs.dialogForm.validate(async valid => {
        if (valid) {
          this.$refs.uploadForm.submit()
        }
      })
    },
    deleteContract(id) {
      this.$confirm('确定要删除此合同吗？', this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure'),
        showCancelButton: false
      }).then(async() => {
        const { code, msg } = await deleteContract(id)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.handleQuery()
        }
      })
    },
    async getAllWarehouseData() {
      const { datas, code } = await getCargoOwner()
      if (!code) {
        this.baseWareHouseData = datas
      }
    },
    cancel(row) {
      this.row = row
      this.cancelForm = Object.assign({}, row)
      this.cancelDialogVisible = true
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.handleQuery()
    },
    handleQuery() {
      this.pager.current = 1
      this.queryClick()
    },
    async queryClick() {
      try {
        this.tableLoading = true
        const { code, datas: { pager, records }} = await getContractList(this.queryParams)
        if (code === 0) {
          this.tableData = records.map(item => { return { ...item, numberLoading: false, signPrintTypeI18: item.signPrintType ? item.signPrintTypeI18 : '' } })
          this.pager = pager
        }
      } finally {
        this.tableLoading = false
      }
    },
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    }
  }
}
</script>

<style scoped lang="scss">
 /deep/ .el-date-editor .el-range-input{
    width:80px!important;
    }
</style>
